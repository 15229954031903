  import * as documentUploadService from "./documentUpload.service";

  // Get filename extension using substring() and lastIndexOf() methods
  export function getExtension(filename) {
    console.log('File extension:', filename.split('.').pop());
    return filename.split('.').pop();
  };


  /**
   * To get temporary urls
   * @param {string} url : url coming from database
   * @returns {string} : return temporary url with timestamp, after time url will not valid
   */
  export  async function  fnGetTempUrl(url) {

    let s3BucketPath = "";
    let urlParts = "";
    s3BucketPath = process.env.VUE_APP_S3_BUCKET_PATH;
    urlParts = url.split(`${s3BucketPath}`);

    try {
      if (urlParts.length > 1) {
        const response = await documentUploadService.getTempUrl({
          key: urlParts[1],
        });

        this.tempUrl = response.data;
        return response.data;
      }
    } catch (error) {
      return "";
    }
  };

  /**
   * Generate random integer number
   * @param {*} min
   * @param {*} max
   * @returns
   */
  export const getRandomNumber = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)

    return Math.floor(Math.random() * (max - min)) + min
  }
