import { http, httpFile } from './http_service';

// Remove doc and update
export function removeDoc(data){
    return http().put(`/documents/remove-doc`, data)
}


export function getTempUrl(data){
    return http().post(`/get-temp-url`, data)
}


