<template>
  <div class="avatar-icon-model" v-if="coverImgUrl">
    <div class="icon-row">
      <div
        :class="{
          'image-view-15': isFileDelete == true,
          'image-view-0': isFileDelete == false,
        }"
      >
        <!-- view file -->
        <a
          @click="fnShowModal()"
          data-toggle="tooltip"
          data-placement="top"
          title="View file"
        >
          <i :class="[fontAwClass, fontAwSize]" class="faw-color"></i>
        </a>
      </div>
      <div class="image-delete" v-if="isFileDelete && coverImgUrl">
        <!-- delete file -->
        <a
          @click="fnDeleteFile()"
          data-toggle="tooltip"
          data-placement="top"
          title="Delete file"
        >
          <i class="far fa-times-circle fs-1000 text-danger"></i>
        </a>
      </div>
    </div>

    <b-modal ref="modal" scrollable size="xl" hide-footer :title="title">
      <div class="d-block" id="disable-text-selection">
        <div v-if="coverImgUrl">
          <!-- for images -->
          <div class="text-center py-2">
            <button class="btn btn-sm" @click="fnImgZoomIn()" :disabled="isMax">
              <i class="fas fa-search-plus fs-2000 text-info"></i>
            </button>
            <button
              class="btn btn-sm"
              @click="fnImgZoomOut()"
              :disabled="isMin"
            >
              <i class="fas fa-search-minus fs-2000 text-info"></i>
            </button>
          </div>

          <div v-if="fnGetExtension(coverImgUrl) != 'pdf'" class="text-center">
            <img
              draggable="false"
              :src="tempUrl"
              class="img-responsive"
              :data-size="widthSize"
            />
          </div>

          <!-- for pdf -->
          <div v-if="fnGetExtension(coverImgUrl) == 'pdf'">
            <embed
              type="text/html"
              :src="tempUrl"
              height="900px"
              width="100%"
              draggable="false"
            />
          </div>
        </div>
        <div v-else>
          <p>Sorry, No image to view</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import * as authService from "../../services/auth.service";
import * as miscellaneousService from "../../services/miscellaneous.service";
export default {
  name: "ModelImageAvatarPreview",
  props: {
    title: {
      type: String,
      default: "",
    },
    coverImgUrl: {
      type: String,
      default: "",
    },
    fontAwClass: {
      type: String,
      default: "fas fa-file-alt",
    },
    fontAwSize: {
      type: String,
      default: "fs-3000",
    },
    isFileDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loginUserObj: {},
      tempUrl: "",
      widthSize: 50,
      isMin: false,
      isMax: false,
    };
  },

  methods: {
    fnShowModal() {
      this.showModal();
    },
    hideModal() {
      this.$refs.modal.hide();
    },
    showModal: async function () {
      this.tempUrl = await miscellaneousService.fnGetTempUrl(this.coverImgUrl);
      this.$refs.modal.show();
    },
    // To get file extension
    fnGetExtension(fileName) {
      return miscellaneousService.getExtension(fileName);
    },
    // To delete file and call  parent methods
    fnDeleteFile() {
      this.$emit("delete-file");
    },
    fnImgZoomIn() {
      let minSize = 10;
      let maxSize = 110;

      if (maxSize == Number(this.widthSize)) {
        this.isMax = true;
        return (this.widthSize = 100);
      }

      this.widthSize = Number(this.widthSize) + minSize;
      this.isMin = false;
    },
    fnImgZoomOut() {
      let minSize = 10;

      if (minSize == Number(this.widthSize)) {
        this.isMin = true;
        return (this.widthSize = 10);
      }

      this.widthSize = Number(this.widthSize) - minSize;
      this.isMax = false;
    },
  },

  mounted() {
    if (this.loginUserObj.role_id != 1 && this.loginUserObj.role_id != 2) {
      document.oncontextmenu = new Function("return false");
    }
  },
  created() {
    this.loginUserObj = authService.getUserFromToken();
  },
};
</script>
<style lang="scss">
@import "../../scss/_colors.scss";
/*
* Repeatable image block
*/
.repeatable-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
/*
* Avatar icon
*/
.avatar-icon-model {
  width: 55px;
  a {
    cursor: pointer;
  }
  a .faw-color {
    color: $primary-color;
  }

  a .faw-color:hover {
    color: $info-color;
    transition: transform 0.5s ease;
  }
  .icon-row {
    display: flex;
    .image-view-15 {
      margin-top: 15px;
    }
    .image-view-0 {
      margin-top: 0px;
    }
  }
}
.img-responsive {
  &[data-size="10"] {
    width: 10vw;
  }
  &[data-size="20"] {
    width: 20vw;
  }
  &[data-size="30"] {
    width: 30vw;
  }
  &[data-size="40"] {
    width: 40vw;
  }
  &[data-size="50"] {
    width: 50vw;
  }
  &[data-size="60"] {
    width: 60vw;
  }
  &[data-size="70"] {
    width: 70vw;
  }
  &[data-size="80"] {
    width: 80vw;
  }
  &[data-size="90"] {
    width: 90vw;
  }
  &[data-size="100"] {
    width: 100vw;
  }
}

#disable-text-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>